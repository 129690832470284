<template>
    <div class="wallet" style="height: 100%;">
        <Top name="钱包收支" back email></Top>
        <div class="wallet_w" style="height: calc(100% - 40px);">
            <van-tabs v-model="active" @change="saleChange">
                <van-tab title="全部"></van-tab>
                <van-tab title="收入明细"></van-tab>
                <van-tab title="支出明细"></van-tab>
            </van-tabs>
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="height: calc(100% - 44px); overflow-y: auto;">
                <van-list
                    class="list_side"
                    v-model="loading"
                    offset="50"
                    :finished="finished"
                    finished-text="没有更多了"
                    :error.sync="error"
                    error-text="请求失败，点击重新加载"
                    @load="onLoad"
                >
                    <van-cell v-for="item in list" :key="item.id">
                        <router-link to="">
                            <div class="detail_list">
                                <div class="detail_list_t">
                                    <span>{{item.bill_type_name}}</span>
                                    <span>{{item.transaction_money}}元</span>
                                </div>
                                <div class="detail_list_b">
                                    <span>时间：{{item.create_time}}</span>
                                    <span>余额：{{item.balance}}</span>
                                </div>
                            </div>
                        </router-link>
                    </van-cell>
                </van-list>
            </van-pull-refresh>
        </div>
    </div>
</template>
<script>
 import Top from '@/components/top.vue'

export default {
    name: 'Wallet',
    components: {
        Top,
    },
    data() {
        return {
            active: 0,
            loading: false,
            finished: false,
            error: false,
            list: [],
            total: 0,
            page: 1,
            isLoading: false
        }
    },
    methods: {
        //下拉刷新
        onRefresh() {
            this.clearHttpRequestingList();
            this.error = false;
            this.list = [];
            this.page = 1;
            this.finished = false;
            this.loading = true;
            this.onLoad();
        },
        //tab切换
        saleChange(name) {
            this.clearHttpRequestingList();
            this.error = false;
            this.list = [];
            this.page = 1;
            if (this.finished) {
                this.finished = false;
            }else{
                this.finished = false;
                this.onLoad();
            }
        },
        //列表加载
        onLoad() {
            if (this.isLoading) {
                this.finished = false;
                this.error = false;
                this.list = [];
                this.page = 1;
                this.isLoading = false;
            }
            //异步更新数据
            //清除缓存
            this.$api.account.walletBill({
                detailed_type: this.active,
                page: this.page
            }).then(res => {
                if (res.code == 200) {
                    if (res.data.data.length > 0) {
                        res.data.data.forEach((item) => {
                            this.list.push(item);
                        });
                        this.total = res.data.total;
                        //加载状态结束
                        this.loading = false;
                        //数据全部加载完成
                        if (this.page * 8  >= this.total) {
                            this.finished = true;
                        }
                        this.page ++;
                        //关掉错误提示
                        this.error = false;
                        this.list.forEach((item) => {
                            if (String(item.transaction_money).indexOf('+') < 0 && String(item.transaction_money).indexOf('-') < 0) {
                                item.transaction_money = item.detailed_type == 1 ? ('+' + item.transaction_money) : ('-' + item.transaction_money);
                            }
                        });
                    }else{
                        //加载状态结束
                        this.loading = false;
                        //数据全部加载完成
                        this.finished = true;
                    }
                }else{
                    //加载状态结束
                    this.loading = false;
                    //错误提示
                    this.error = true;
                }
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
                console.log(err);
            });
        },
    }
}
</script>
<style scoped>
.wallet{
    min-height: 100%;
    background-color: #ececec;
}
/* .wallet_w{
    background-color: #ececec;
} */
.wallet_w .van-cell{
    background: none;
    padding: 6px 16px;
}
.list_side{
    /* margin-top: .84375rem; */
    padding-top: .84375rem;
}
.list_side .van-cell::after{
    border-bottom: none;
}
.detail_list{
    background-color: #fff;
    border-radius: 5px;
    padding: .5rem .8125rem;
}
.detail_list_t,
.detail_list_b{
    display: flex;
    justify-content: space-between;
    font-size: .8125rem;
    line-height: 1.75rem;
    border-bottom: 1px dashed #ddd;
}
.detail_list_b{
    border-bottom: none;
}
.detail_list_t span:first-child{
    color: #313131;
}
.detail_list_t span:last-child{
    color: #ff4e00;
}
.detail_list_b span:last-child{
    color: #313131;
}
</style>
<style>
.wallet_w .van-tabs__wrap{
    border-bottom: 1px solid #ddd;
}
.wallet_w .van-tabs__wrap .van-tabs__line{
    background-color: #FF4E00;
}
.wallet_w .van-tabs__wrap .van-tab--active{
    font-weight: 700;
    color: #FF4E00;
}
</style>